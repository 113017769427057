ul {
  display: table;
  margin: 0 auto;
}

.custom {
  min-height: 350px;
}

li {
  padding: 5px 0px;
}

.row-margin {
  padding: 6px;
}

.dimensions {
  margin-top: 6px;
  margin-bottom: 6px;
  margin-left: 6px;
}


.button{
  text-align: center;
  width: 50%;
}

.button1{
  width: 50%;
}

.text{
  width: 175px;
  margin: auto;
  padding-top: 6px;
  padding-bottom: 6px;
}
