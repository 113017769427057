
.Label {
  font-weight: bold;
}

.TileRow {
  padding: 5px 0;
}

.Module {
  margin: 5px 0;
}
